.toggle-icon {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease-in-out;
}

.toggle-icon--active {
    transform: rotate(-180deg);
}

.transactions-details {
    padding: 8px !important;
    position: relative;
}

.transactions-details-header {
    font-weight: bold;
    color: rgba(0, 0, 0, .5);
}


.transactions-details:first-child {
    border-bottom: none !important;
}

.transactions-details .tree-node {
    width: 24px;
    height: 50px;
    min-height: 20px;
    border-bottom: 2px solid #CCCCCC;
    border-left: 2px solid #CCCCCC;
    border-bottom-left-radius: 10px;
    margin: 0 0 18px 10px;
    position: absolute;
    bottom: 0;
}

.favourite-project {
    border: none;
    height: 40px;
    border-radius: 12px;
    background: transparent;
    margin: -4px 8px;
    font-size: 30px;
    color: gray;
}

.favourite-project--active {
    color: rgb(255, 157, 133);
}